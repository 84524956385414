<template>
  <div class="page">
    <div class="form">
      <div class="form_title color">聊城一对一家教</div>
      <el-form ref="form" :model="form">
        <el-form-item style="margin-bottom: 39px">
          <el-input placeholder="用户名" v-model="form.userName">
            <template slot="prepend"
              ><i class="iconfont icon-yonghu"></i
            ></template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-input show-password placeholder="密码" v-model="form.password">
            <template slot="prepend"
              ><i class="iconfont icon-mima"></i
            ></template>
          </el-input>
        </el-form-item>
        <div class="option flex j-sb">
          <div class="jzmm">
            <el-checkbox v-model="jzmm">记住密码</el-checkbox>
          </div>
          <div class="wjmm color">忘记密码</div>
        </div>
        <el-form-item>
          <div class="btn" @click="login">登录</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
  
  <script>
  import { login } from "@/api";
  export default {
    data() {
      return {
        jzmm: false,
        form: {},
      };
    },
    created() {
      let account = localStorage.getItem("accountLC");
      if (account) {
        this.form = JSON.parse(account);
        this.jzmm = true;
      }
    },
    methods: {
      async login() {
        if (!this.form.userName) {
          this.$message({
            message: "请输入账号",
            type: "warning",
          });
          return;
        }

        if (!this.form.password) {
          this.$message({
            message: "请输入密码",
            type: "warning",
          });
          return;
        }

        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
        });

        login(this.form).then((res) => {
          if (res.code == 200) {
            if (this.jzmm) {
              localStorage.setItem("accountLC", JSON.stringify(this.form));
            } else {
              localStorage.removeItem("accountLC");
            }
            sessionStorage.setItem("token", res.data.access_token);
            setTimeout(() => {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              loading.close();
            }, 1000);

            setTimeout(() => {
              this.$router.push("/home");
            }, 2000);
          } else {
            setTimeout(() => {
              this.$message({
                message: res.message,
                type: "error",
              });
              loading.close();
            }, 1000);
          }
        });

        // console.log(data);
      },
    },
  };
</script>
  <style lang="less" scoped>
.page {
  background: rgba(159, 224, 254, 1);
  position: relative;
  background: url("../assets/img/login.png");
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 440px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 16px 0px rgba(146, 211, 250, 0.35);
    border-radius: 10px;
    padding: 0 30px;
    box-sizing: border-box;
    .form_title {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 26px;
      margin-top: 62px;
      margin-bottom: 42px;
    }
    .icon-yonghu {
      font-size: 18px;
    }
    .wjmm {
      font-size: 14px;
      cursor: pointer;
    }
    .btn {
      width: 100%;
      margin-top: 39px;
      height: 50px;
      background: #07a8e3;
      border-radius: 25px;
      font-size: 24px;
      text-align: justify;
      color: #ffffff;
      line-height: 50px;
      padding: 0 115px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .btn::after {
      display: inline-block;
      width: 100%;
      content: "";
    }
  }
}
</style>